//
// Scroll to (anchor links)
//

'use strict';

var SearchDropdown = (function() {

	//
	// Variables
	//

	var $search = $('.search-dropdown');


	//
	// Methods
	//

	function init($this) {
        var $input = $this.find('.search-control')
		var $dropdownMenu = $this.find('.search-dropdown-menu')

        $input.on('focusin', function() {
            $this.addClass('show')
        })

        $input.on('focusout', function() {
            $this.removeClass('show')
        })
	}


	//
	// Events
	//

    if ($search.length) {
		$search.each(function() {
			init($(this));
		});
	}
})();
