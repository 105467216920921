//
// Fancybox
//

'use strict';

var FancyboxInit = (function() {

	var $fancybox = $('[data-fancybox]')


	function init($this) {
		$this.fancybox({
			protect: true,
			// toolbar: false,
			buttons: [
				// "zoom ",
				//"share",
				// "slideShow",
				//"fullScreen",
				//"download",
				// "thumbs",
				"close"
			],
            animationEffect: "fade",
            transitionEffect: "fade",
            baseTpl:
                '<div class="fancybox-container" role="dialog" tabindex="-1">' +
                    '<div class="fancybox-bg"></div>' +
                    '<div class="fancybox-inner">' +
                        '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
                        '<div class="fancybox-toolbar">{{buttons}}</div>' +
                        '<div class="fancybox-navigation">{{arrows}}</div>' +
                        '<div class="fancybox-stage"></div>' +
                        '</div>' +
                '</div>',
			btnTpl: {
    			close:
      				'<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
      				'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"/></svg> Inchide' +
      				"</button>",
			}
		});
	}

	//
	// Events
	//

	// Init
	if ($fancybox.length) {
		init($fancybox);
	}

})();
